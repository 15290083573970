import dynamic from 'next/dynamic'

import { Container, Row, Column } from './base/gridview'

const Button = dynamic(() => import('./base/button'))
const Heading = dynamic(() => import('./base/heading'))
const Html = dynamic(() => import('./base/html'))
const Image = dynamic(() => import('./base/image'))
const Section = dynamic(() => import('./base/section'))
const Video = dynamic(() => import('./base/video'))
const Breadcrumbs = dynamic(() => import('./base/breadcrumbs'))

const UlupinarBanner = dynamic(() => import('./widgets/ulupinar-banner'))
const UlupinarSlider = dynamic(() => import('./widgets/ulupinar-slider'))
const UlupinarGlobal = dynamic(() => import('./widgets/ulupinar-global'))
const UlupinarJourney = dynamic(() => import('./widgets/ulupinar-journey'))
const UlupinarPageHead = dynamic(() => import('./widgets/ulupinar-page-head'))
const UlupinarHeader = dynamic(() => import('./widgets/ulupinar-header'))
const UlupinarContact = dynamic(() => import('./widgets/ulupinar-contact'))
const UlupinarFooter = dynamic(() => import('./widgets/ulupinar-footer'))
const CapacityBox = dynamic(() => import('./widgets/capacity-box'))
const UlupinarPrefers = dynamic(() => import('./widgets/ulupinar-prefers'))
const UlupinarWorks = dynamic(() => import('./widgets/ulupinar-works'))
const UlupinarGallery = dynamic(() => import('./widgets/ulupinar-gallery'))
const UlupinarContent = dynamic(() => import('./widgets/ulupinar-content'))
const UlupinarReferences = dynamic(
  () => import('./widgets/ulupinar-references')
)
const UlupinarVideoBanner = dynamic(
  () => import('./widgets/ulupinar-video-banner')
)

export default function getter(name: string) {
  switch (name) {
    case 'heading':
      return Heading
    case 'html':
      return Html
    case 'container':
      return Container
    case 'row':
      return Row
    case 'column':
      return Column
    case 'button':
      return Button
    case 'image':
      return Image
    case 'section':
      return Section
    case 'video':
      return Video
    case 'ulupinar-banner':
      return UlupinarBanner
    case 'ulupinar-slider':
      return UlupinarSlider
    case 'ulupinar-global':
      return UlupinarGlobal
    case 'ulupinar-journey':
      return UlupinarJourney
    case 'ulupinar-page-head':
      return UlupinarPageHead
    case 'ulupinar-header':
      return UlupinarHeader
    case 'ulupinar-contact':
      return UlupinarContact
    case 'ulupinar-footer':
      return UlupinarFooter
    case 'capacity-box':
      return CapacityBox
    case 'ulupinar-prefers':
      return UlupinarPrefers
    case 'ulupinar-faaliyet':
      return UlupinarWorks
    case 'ulupinar-gallery':
      return UlupinarGallery
    case 'ulupinar-references':
      return UlupinarReferences
    case 'ulupinar-video-banner':
      return UlupinarVideoBanner
    case 'ulupinar-content':
      return UlupinarContent
  }
}
